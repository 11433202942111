exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assets-js": () => import("./../../../src/pages/assets.js" /* webpackChunkName: "component---src-pages-assets-js" */),
  "component---src-pages-certification-js": () => import("./../../../src/pages/certification.js" /* webpackChunkName: "component---src-pages-certification-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-js": () => import("./../../../src/pages/main.js" /* webpackChunkName: "component---src-pages-main-js" */),
  "component---src-pages-module-js": () => import("./../../../src/pages/module.js" /* webpackChunkName: "component---src-pages-module-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */)
}

